import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image1 from '../images/medal.png';
import Image2 from '../images/medal.png';
import Image3 from '../images/medal.png';

function DarkVariantExample() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 d-none d-md-block">
                    <div className="row">
                        <div className="col-md-4">
                            <Card className="custom-card" style={{ borderRadius: '10px', border: '1px solid #ccc' }}>
                                <Card.Img variant="top" src={Image1} style={{ borderRadius: '50%', width: '50%', margin: 'auto', marginTop: '20px' }} />
                                <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem' }}>1<sup>st</sup> Runner Up</Card.Title>
                                    <Card.Text style={{ textAlign: 'center', color: '#F1B61E' }}>
                                        LKR 75,000
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card className="custom-card" style={{ borderRadius: '10px', border: '1px solid #F1B61E' }}>
                                <Card.Img variant="top" src={Image2} style={{ borderRadius: '50%', width: '50%', margin: 'auto', marginTop: '20px' }} />
                                <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem' }}>Winner</Card.Title>
                                    <Card.Text style={{ textAlign: 'center', color: '#F1B61E' }}>
                                        LKR 125,000
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card className="custom-card" style={{ borderRadius: '10px', border: '1px solid #ccc' }}>
                                <Card.Img variant="top" src={Image3} style={{ borderRadius: '50%', width: '50%', margin: 'auto', marginTop: '20px' }} />
                                <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem' }}>2<sup>nd</sup> Runner Up</Card.Title>
                                    <Card.Text style={{ textAlign: 'center', color: '#F1B61E' }}>
                                        LKR 50,000
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 d-md-none">
                    <Carousel>
                        <Carousel.Item>
                            <Card className="custom-card" style={{ borderRadius: '10px', border: '1px solid #ccc' }}>
                                <Card.Img variant="top" src={Image1} style={{ borderRadius: '50%', width: '50%', margin: 'auto', marginTop: '20px' }} />
                                <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem' }}>1<sup>st</sup> Runner Up</Card.Title>
                                    <Card.Text style={{ textAlign: 'center', color: '#F1B61E' }}>
                                        LKR 75,000
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Card className="custom-card" style={{ borderRadius: '10px', border: '1px solid #F1B61E' }}>
                                <Card.Img variant="top" src={Image2} style={{ borderRadius: '50%', width: '50%', margin: 'auto', marginTop: '20px' }} />
                                <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem' }}>Winner</Card.Title>
                                    <Card.Text style={{ textAlign: 'center', color: '#F1B61E' }}>
                                        LKR 125,000
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Card className="custom-card" style={{ borderRadius: '10px', border: '1px solid #ccc' }}>
                                <Card.Img variant="top" src={Image3} style={{ borderRadius: '50%', width: '50%', margin: 'auto', marginTop: '20px' }} />
                                <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem' }}>2<sup>nd</sup> Runner Up</Card.Title>
                                    <Card.Text style={{ textAlign: 'center', color: '#F1B61E' }}>
                                        LKR 50,000
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default DarkVariantExample;
