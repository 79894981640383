import React from "react";
import { MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import regIcon from "../images/registration-form.png";
import submissionIcon from "../images/submission.png"
import workshop1Icon from "../images/onlineWorkshopSeries1.png"
import workshop2Icon from "../images/onlineWorkshopSeries2.png"
import trophyIcon from "../images/trophy.png"

export default function App() {
  return (
    <MDBContainer fluid className="py-5">
      <div className="main-timeline">
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={regIcon} alt="Registration Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Registration Period</h3>
              </div>
              <br></br>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Deadline: 1st of July 2024</p>
              <p className="mb-0">
                Upon the opening of registrations, individuals interested in participating can complete their team details through the provided registration form and access the sample proposal structure by visiting <a href="https://www.hackx.lk">www.hackx.lk</a>.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={submissionIcon} alt="Submissions Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Proposal Submissions Open</h3>
              </div>
              <br></br>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Deadline : 14th of July 2024</p>
              <p className="mb-0">
                All registered applicants are required to prepare their proposals in accordance with the provided sample structure and submit them through the official website, <a href="https://www.hackx.lk">www.hackx.lk</a>.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={workshop2Icon} alt="Online Workshop Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>ideaX <br></br> <h5>(Semi Finals of hackX-Initial Ideathon)</h5></h3>
              </div>
              <br></br>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Date: 17th of August 2024</p>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Venue: Department of Industrial Management, University of Kelaniya</p>
              <p className="mb-0">
                Following the initial screening process, 30 teams showcasing the most innovative ideas will be selected. Each team will have 7 minutes to pitch their ideas, followed by a 3-minute Q&A session. The top 12 teams will then advance to compete at the Grand Finals of hackX 9.0
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={workshop1Icon} alt="Online Workshop Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>designX</h3>
              </div>
              <br></br>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Date: 24th of August 2024-14th of September 2024</p>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Venue:</p>
              <p className="mb-0">
                A workshop series of four skill-building sessions, tailored for finalists at the Grand Finals of hackX 9.0
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={trophyIcon} alt="Finals Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>hackX 9.0: Grand Finals</h3>
              </div>
              <br></br>
              <p style={{ color: "#FFC100", fontWeight: "bold" }}>Date: 29th of September 2024</p>
              <p className="mb-0">
                This event represents the ultimate stage where contestants will present their further refined innovative ideas. Fifteen teams will compete: twelve teams selected from ideaX and three teams from ideasprint.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </MDBContainer>
  );
}
